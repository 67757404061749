.top-system-wrapper {
    display: flex;
    flex-direction: row;
    width: 1080px;
    margin-bottom: 16px;
}

.top-system-container {
    background-color: #31313C;
    font-family: "Roboto", Sans-serif;
    width: 350px;
    height: 256px;
    border-radius: 4px;
    margin-top: 8px;
    justify-content: center;
    display: flex;
    text-align: center;
    line-height: normal;
}

.top-system-container:not(:last-of-type) {
    margin-right: 16px;
}

.top-system-img {
    margin-top: 48px;
    margin-bottom: 8px;
}

.camera-top {
    width: 128px;
    height: 128px;
}

.discord-top {
    width: 112px;
    height: 128px;
}

.leagues-top {
    width: 170px;
    height: 128px;   
}

@media (max-width: 575px) {
    .camera-top {
    width: 100px;
    height: 100px;
}

.discord-top {
    width: 87.5px;
    height: 100px;
}

.leagues-top {
    width: 132.8px;
    height: 100px;   
}
}
