.leftbar-container {
    margin-left: 8px;
    border-radius: 4px;
    width: 378px;
    background-color: #31313C;
    display:inline-block;
    vertical-align: top;
    height: 47.8%;
    box-shadow: 0 0 8px rgba(0,0,0,.15);
    margin-bottom: 8px;
}
.text-light-custom {
    font-weight: bold;
    font-weight: 700!important;
    color: #ff90f0;
    border-radius: 4px;
    margin: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    font-family: 'Raleway', Sans-serif;
}
.tournament {
    padding-top: 5px;
    padding-bottom: 5px;
}
.default-text {
    font-family: 'Roboto', Sans-serif;
    font-weight: 700!important;
    font-size: 18.2px;
}
.inventory {
    padding-bottom: 8px;
    padding-top: 8px;
}
.seperator {
    border-top: 1px solid;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: #1C1C1F;
}
.inventory-inner {
    padding-bottom: 4px;
    flex-grow: 1;
    height: 270px;
}
.inventory-item {
    width: 30%;
    display: inline-block;
    background-color: #2798e3;
    border-radius: 4px;
    border: 0;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-top: 4px;
}
.inventory-item:hover {
    background-color: #3da3e7;
}
.inventory-item:active {
    border: 0;
}
.img-money {
    width: 30%;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-top: 4px;
}
.inventory-bottom {
    margin-top: 4px;
    padding-top: 8px;
    padding-bottom: 0;
}
.inventory button {
    color: #fff;
    border-color: none;
}
.inventory button:hover, .inventory button:focus {
    outline-style: solid;
    outline-width: 2px;
    outline-color: #77b7e2;
}