.img-logo-kjartan {
    width: 64px;
    height: 47px;
}
.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.brand-text {
    font-family: 'Sour', sans-serif !important;
    color: #ff90f0;
    text-align: left;
    text-transform: uppercase;
    font-size: 1.5em;
    line-height: 0.75;
    font-weight: 500;
}
.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.brand-image {
    margin-bottom: 7px;
    margin-top: 7px;
    margin-left: 8px;
    width: 158px;
    height: 32px;
}

.navbar-expand-lg .navbar-nav .nav-item {
    padding: 1rem;
}

.pink-nav {
    height: 16px;
    margin-top: 12px;
    width: 16px;
    margin-right: 8px;
    border-bottom: 3px solid #ff90f0;
}

.pink-nav-line {
    height: 60px;
    border-right: 3px solid #ff90f0;
    margin-left: 16px;
}

.pink-nav-line-last {
    height: 28px;
    border-right: 3px solid #ff90f0;
    margin-left: 16px;
}

.search-container-nav {
    display: flex;
}

.search-container-nav .pink-nav {
    margin: 8px;
    margin-left: 0;
}

.search-container-nav {
    padding-bottom: 0 !important;
}

.search-container-nav .pink-nav-line {
    margin-left: 8px;
}

.navbar-expand-lg .navbar-nav .nav-item {
    display: flex;
    padding: 0;
}
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-item {
        padding: 0;
    }
    .pink-nav-line {
        margin-left: 6px;
    }

    .pink-nav {
        visibility: hidden;
    }

    .pink-nav-line {
        visibility: hidden;
    }

    .pink-nav-line-last {
        visibility: hidden;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }

    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .nav-item {
        padding-bottom: 21px;
        padding-top: 21px;
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.collapse:not(.show) {
    display: none;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-brand {
    padding-top: 2px;
    padding-bottom: 0;
    padding-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
}

.navbar-container-custom {
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ff90f0' d='M21 13H3c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.4 1-1s-.4-1-1-1zm0-7H3c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.4 1-1s-.4-1-1-1zm0 14H3c-.6 0-1 .4-1 1s.4 1 1 1h18c.6 0 1-.4 1-1s-.4-1-1-1z'/%3E%3C/svg%3E");
    margin-bottom: 6px;
    margin-top: 2px;
}

.navbar-dark .navbar-toggler {
    background-color: #202124;
    border-color: #515163;
    border-radius: 0;
    border-left: 0;
}

.navbar-dark .navbar-toggler:focus {
    border-color: #ff90f0;
    outline: none;
}

.navbar {
    background-color: #31313C;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .15);
    padding: 0;
    position: relative;
    display: -ms-flexbox;
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.nav-item {
    color: #ff90f0;
    text-align: left;
    text-transform: uppercase;
    font-family: 'Raleway-Bold', sans-serif;
    letter-spacing: 0.01rem;
    font-size: 1.2em;
    line-height: 0.75;
    font-weight: 600;
    padding-right: 32px;
    padding-bottom: 6px;
    padding-top: 10px;
    height: 100%;
}

.full-link {
    padding-top: 21px;
    padding-bottom: 21px;
    font-size: 24px;
}

.nav-item:hover {
    text-shadow: 0 0 15px #ff90f0;
}

.custom-navbar-container {
    display: inline-block;
    border-radius: 30px;

}

.custom-logout {
    background-color: #202124;
    color: #e8eaed;
    font-size: 14px;
    height: 32px;
    letter-spacing: 0.25px;
    padding: 0 10px;
    align-items: center;
    cursor: pointer;
    font-family: "Google Sans", arial, sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    border-radius: 5px;
}

.custom-span {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: "Google Sans", arial, sans-serif;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    font-size: 14px;
    height: 32px;
    letter-spacing: 0.25px;
    padding: 0 10px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    text-align: center;
    white-space: nowrap;
    margin-top: 10px;

}

.custom-nav-signout {
    border-radius: 4px;
}

/* Navbar.css */

.navbar {
    /* Add other styles for the navbar here */

    /* Use flexbox to align items in the navbar */
    display: flex;
    justify-content: space-between;
    /* Centers items horizontally */
    align-items: center;
    /* Centers items vertically */
}

.search-bar {
    /* Add other styles for the search bar here */
    display: flex;
    align-items: center;
}

.search-bar input {
    /* Add other styles for the search input here */
    padding: 5px;
    margin-right: 10px;
    /* Add some space between the input and button */
}

.search-bar button {
    /* Add other styles for the search button here */
    padding: 5px 10px;
}

.right-item-nav {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.search-container-nav {
    margin-left: auto !important;
    margin-right: auto !important;
}

/* SEARCH BAR*/
.search-container-nav {
    max-width: 100%;
    padding: 0.5rem;
}

.search-container-nav-inner {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.search-img {
    width: 32px;
    height: 32px;
}

.search-input-nav {
    border-radius: 0;
    background-color: #212529;
    border-left: 1px solid #515163;
    border-top: 1px solid #515163;
    border-bottom: 1px solid #515163;
    border-right: none;
    width: 280px;
    font-size: 1rem;
    height: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #f9f9f9;
    font-size: 20px;
    font-family: 'Raleway-Bold', sans-serif;
}

.active {
    background-color: #515163;
    color: #ff90f0;
}

.search-input-nav:focus {
    outline: none;
    border-top: 1px solid #ff90f0;
    border-left: 1px solid #ff90f0;
    border-bottom: 1px solid #ff90f0;
}

.search-input-nav:focus+.search-button-nav {
    border-top: 1px solid #ff90f0;
    border-right: 1px solid #ff90f0;
    border-bottom: 1px solid #ff90f0;
}

.search-button-nav {
    background-color: #212529 !important;
    outline: none;
    border: none;
    height: 3rem;
    border-top: 1px solid #515163;
    border-right: 1px solid #515163;
    border-bottom: 1px solid #515163;
    min-height: 48px;
}

.search-button-nav:focus {
    outline: none;
}

.dropdown-options {

    position: absolute;
    background-color: #212529 !important;
    border-top: 1px solid #515163;
    border-right: 1px solid #515163;
    border-bottom: 1px solid #515163;
    outline: none;
    border: none;
    color: #ff90f0;
    width: 68px;
    text-align: center;
    font-family: 'Raleway-Bold', sans-serif;
    vertical-align: middle;
}

.dropdown-option {
    outline: none;
    border-bottom: 1px solid #515163;
    border-left: 1px solid #515163;
    border-right: 1px solid #515163;
    height: 1.9rem;
    vertical-align: middle;
    padding-top: 5px;
}

.dropdown-option:hover {
    cursor: pointer;
    color: #f9f9f9;
}

.dropdown-button {
    height: 3rem;
    margin-left: 8px;
}

.dropdown-button-toggle {
    height: 3rem;
    color: #ff90f0;
    background-color: #212529 !important;
    outline: none;
    border: none;
    border-top: 1px solid #515163;
    border-left: 1px solid #515163;
    border-bottom: 1px solid #515163;
    font-family: 'Raleway-Bold', sans-serif;
    display: flex;
    margin: auto;
    align-items: center;
    padding-right: 8px;
}

.dropdown-button-toggle:hover {
    cursor: pointer;
}

.dropdown-button-toggle:focus {
    outline: none;
    border: 1px solid #ff90f0;
    padding-right: 7px;
}

.dropdown-button-toggle p {
    margin-left: 8px;
    margin-right: 4px;
}

.down-pointer {
    font-size: 12px;
}

.search-button:hover {
    cursor: pointer;
}

.search-button:focus {
    outline: none;
}