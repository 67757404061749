.active-pink {
    background-color: #515163;
    color: #ff90f0;

}

.inactive-pink {
    color: #61616b
}

/* LOOKUP CONTAINTER */

.lookup-wrapper {
    display: inline-block;
    width: 720px;
    width: 100%;
}

.lookup-container {
    border-radius: 4px;
    min-height: 110px;
    display: inline-block;
    background-color: #31313C;
    font-family: "Roboto", Sans-serif;
    color: #fff;
    margin-bottom: 0;
    min-width: 720px;
    width: 100%;
    margin-top: 16px;
    vertical-align: top;
    box-shadow: 0 0 8px rgba(0, 0, 0, .15);
}
.centered-container {
    display: flex;
    justify-content: center; /* Centers items horizontally */
    margin-left: auto;
    margin-right: auto;
    width: 720px;
}

.lookup-player {
    margin-left: 16px;
    margin-top: 11px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}

.text-light {
    font-weight: 500 !important;
    color: #f9f9f9;
    font-size: large;
}
.text-lighter {
    font-weight: 300 !important;
    color: #f9f9f9;
    font-size: large;
}

.youtube-lookup {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    width: 1rem;
    vertical-align: middle;
    margin-top: 4px;
}

.twitch-lookup {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0.5rem;
    width: 1rem;
    margin-top: 4px;
}

/*  EXTRA ACCOUNTS  */
.extra-accounts {
    margin-left: 24px;
    margin-top: 16px;
    align-items: center;
    display: inline-block;
    flex: 2;
}

.summoner-icon {
    border-radius: 50%;
    width: 32px;
    margin-right: 8px;
}

.individual-account {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
}
.extra-numerator {
    height: 16px;
    margin-bottom: 15px;
    width: 16px;
    margin-right: 8px;
    border-bottom: 2px solid #ff90f0;
    border-left: 2px solid #ff90f0;
}