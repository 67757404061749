.footer-container {
    justify-content: center;
    display:flex;
    font-family: "Roboto", Sans-serif;
    flex-direction: column;
}
.text-light-footer {
    font-size: 18px!important;
    margin-right: auto;
    margin-left: auto;
    color: #9E9EB1!important;
    font-weight: 100;
    text-align: center;
}
.footer-socials{
    width: 20px;
    height: 20px;
    margin-left: 6px;
    margin-right: 2px;
}
.footer-inner {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    margin-top: 8px;
}

/* CookiesNotice.css */

.cookies-notice {
    position: fixed;
    bottom: 50px;
    left: 0;
    width: 100%;
    padding: 16px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-content:center;
    justify-content: center;

  }
  
  .cookies-notice p {
    margin: 0;
    padding: 8px 0;
    font-size: 14px;
  }

  .cookies-button {
    position: fixed;
    bottom: 16px;
    left: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    align-content:center;
    justify-content: center;
    border-radius: 10px;
  }
  
  .cookies-button button {
    background-color: #ff90f0;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .cookies-button button:hover {
    background-color: #c03bae;
  }
  .raechu-text {
    color:#ff90f0!important;
}