.player-profile-wrapper {
    border-radius: 4px;
    height: 310px;
    display: inline-block;
    background-color: #31313C;
    font-family: "Roboto", Sans-serif;
    color: #fff;
    margin-bottom: 0;
    width: 432px;
    vertical-align: top;
    box-shadow: 0 0 8px rgba(0, 0, 0, .15);
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
}

.summoner-icon-big {
    height: 100px;
    border-radius: 50%;
    margin: 16px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .60);
}

.white-svg {
    filter: brightness(0) invert(1);
}

.profile-info {
    margin-top: 4px;
    width: 50%;
}

.profile-name {
    font-size: 30px!important;
}

.upper-pfp {
    display: flex;
    align-items: center;
    justify-content: left;
}
.role-pos {
    margin-left: 12px;
}
.card-item {
    margin-top: 8px!important;
}
.pink-line {
    margin-left: 12px;
    margin-right: 12px;
    border-left: 3px solid #ff90f0;
    height: 32px;
    width: 1px;
}
.flag-icon-pfp {
    margin-right: 12px!important;
}
.profile-contents {
    display: flex;
}
.profile-right div{
    text-align: right;
    justify-content: right;
    display: flex;
}
.pink-line-right {
    margin-left: 12px;
    margin-right: 12px;
    border-right: 3px solid #ff90f0;
    height: 32px;
    width: 1px;
}
.socials-item {
    margin-right: 8px;
}
.socials-user {
    width: 16px;
    height: 16px;
}
.accounts-wrapper {
    display: inline-block;
    margin-top: 8px;
}
.accounts-container {
    border-radius: 4px;
    min-height: 110px;
    display: flex;
    background-color: #31313C;
    font-family: "Roboto", Sans-serif;
    color: #fff;
    margin-bottom: 0;
    width: 616px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    vertical-align: top;
    box-shadow: 0 0 8px rgba(0, 0, 0, .15);
}
.pfp-container {
    border-radius: 4px;
    height: 100px;
    display: inline-block;
    background-color: #31313C;
    font-family: "Roboto",Sans-serif;
    color: #fff;
    margin-bottom: 0;
    width: 330px;
    vertical-align: top;
    box-shadow: 0 0 8px rgba(0,0,0,.15);
    margin-left: 8px;
}
.account-pfp {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: left;
    max-width: 322px
}
.summoner-icon-med {
    height: 48px;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, .60);
    margin-right: 8px;
}
.account-name {
    font-size: 24px!important;
}
.opgg-logo {
    height: 14px;
    background-color: #5383E8;
    margin-top: 6px;
}
.opgg-wrapper {
   /* border: 2px solid white;*/
    border-radius: 7%;
    padding-right: 4px;
    padding-left: 4px;
    padding-top: 4px;
    padding-bottom: 1px;
    background-color: #5383E8;
    margin-top: 8px;
    width: 57px;
}
.stats-pfp {
    margin-left: auto;
    align-items: center;
    display: flex;
    text-align: right;
    color: #9E9EB1;
}
.stats-rank {
    height: 94px!important;
    margin-top: 8px;
}
.flex-container {
    display: inline-block;
}