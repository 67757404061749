.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.team-image {
  height: 32px;
  width: 32px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html,
body,
#root {
  justify-content: center;
}

.wrapper-ladder-10 {
  width: auto;

}

.wrapper {
  display: block;
  width: 1080px;
  margin-top: 92px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  height: 720px;
}

.TopTenLadder-container {
  width: 686px;
}

.right-wrapper {
  display: inline-block;
  height: 720px;
}

.pink-text {
  color: #ff90f0;
  text-align: left;
  text-transform: uppercase;
  font-family: 'Raleway-Bold', sans-serif;
  letter-spacing: 0.2rem;
  /*letter-spacing: 0.109375rem;
    width: calc(100% + 0.125rem);*/
  font-size: 1.5em;
  line-height: 0.75;
  font-weight: 600;
}
button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@media (max-width: 992px) {
  .wrapper {
    max-width: 556px;
  }
  .wrapper .search-button {
    visibility: hidden;
    border: 0;
    padding: 0;
  }
  .wrapper .search-input {
    width: 100%;
  }
  .wrapper .Search-container {
    width: 100%;
  }
  .wrapper .centered-container {
    width: 100%;
  }
  .wrapper .lookup-container {
    min-width: 556px;
  }
  .TopTenLadder-container {
    max-width: 556px;
  }
  .right-wrapper {
    width: 100%;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
  }
  .leftbar-container {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .chatbar-container {
    width: 100%;
    margin-left: 0;
  }
  .top-system-wrapper {
    width: auto!important;
    max-width: 556px;
  }
  .top-system-wrapper .top-system-container {
    width: 556px;
  }
  .discord-container {
    margin: 2rem auto!important;
  }
  .TopTenLadder-container {
    margin-right: 0;
  }
}

@media (min-width: 576px) {
  .wrapper-ladder-10 .team {
      margin-left: 1rem;
      margin-right: 1rem;
      max-width: 4rem;
  }
  .wrapper-ladder-10 .rank {
      margin-left: 1rem;
      margin-right: 1rem;
      max-width: 4rem;
  }
  .wrapper-ladder-10 .role {
      margin-left: 1rem;
      margin-right: 1rem;
      max-width: 4rem;
  }
  .wrapper-ladder-10 .youtube {
      margin-right: 1rem;
      max-width: 4rem;
  }

}
@media (max-width: 734px) {
  .wrapper {
    width: auto;
    margin-top: 142px;
  }
}

@media (max-width: 575px) {
  .search-button-nav {
    visibility: hidden;
    width: 0;
    padding: 0;
  }
  .search-input-nav {
    border-right: 1px solid #515163;
    width: 260px;
  }
  .top-system-wrapper {
    height: 128px!important;
  }
  .top-system-container {
    height: 120px!important;
  }
  .top-system-container p{
    visibility: hidden;
  }
  .top-system-img {
    height: 100px!important;
    margin-top: 12px!important;
  }
  .TopTenLadder-container {
    width: 100%;
  }
  .Ladder-container-10 {
      max-width: 556px;
  }
  .wrapper-ladder-10 .team {
      margin-left: 1rem;
      margin-right: 1rem;
      max-width: 4rem;
  }
  .wrapper-ladder-10 .rank {
      margin-left: 1rem;
      margin-right: 1rem;
      max-width: 4rem;
  }
  .wrapper-ladder-10 .role {
      margin-left: 1rem;
      margin-right: 1rem;
      max-width: 4rem;
  }
  .wrapper-ladder-10 .youtube {
      margin-right: 1rem;
      max-width: 4rem;
      display: none;
  }
  .wrapper-ladder-10 .twitch {
      margin-right: 1rem;
      max-width: 4rem;
      display: none;
  }
  .chatbar-container {
    width: auto;
  }
}

/* Afghanistan */
.flag-icon-af {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/af.svg);
}

/* Albania */
.flag-icon-al {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/al.svg);
}

/* Algeria */
.flag-icon-dz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/dz.svg);
}

/* Andorra */
.flag-icon-ad {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ad.svg);
}

/* Angola */
.flag-icon-ao {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ao.svg);
}

/* Antigua and Barbuda */
.flag-icon-ag {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ag.svg);
}

/* Argentina */
.flag-icon-ar {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ar.svg);
}

/* Armenia */
.flag-icon-am {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/am.svg);
}

/* Australia */
.flag-icon-au {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/au.svg);
}

/* Austria */
.flag-icon-at {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/at.svg);
}

/* Azerbaijan */
.flag-icon-az {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/az.svg);
}

/* Bahamas */
.flag-icon-bs {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bs.svg);
}

/* Bahrain */
.flag-icon-bh {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bh.svg);
}

/* Bangladesh */
.flag-icon-bd {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bd.svg);
}

/* Barbados */
.flag-icon-bb {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bb.svg);
}

/* Belarus */
.flag-icon-by {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/by.svg);
}

/* Belgium */
.flag-icon-be {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/be.svg);
}

/* Belize */
.flag-icon-bz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bz.svg);
}

/* Benin */
.flag-icon-bj {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bj.svg);
}

/* Bhutan */
.flag-icon-bt {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bt.svg);
}

/* Bolivia */
.flag-icon-bo {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bo.svg);
}

/* Bosnia and Herzegovina */
.flag-icon-ba {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ba.svg);
}

/* Botswana */
.flag-icon-bw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bw.svg);
}

/* Brazil */
.flag-icon-br {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/br.svg);
}

/* Brunei Darussalam */
.flag-icon-bn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bn.svg);
}

/* Bulgaria */
.flag-icon-bg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bg.svg);
}

/* Burkina Faso */
.flag-icon-bf {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bf.svg);
}

/* Burundi */
.flag-icon-bi {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bi.svg);
}

/* Cabo Verde */
.flag-icon-cv {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cv.svg);
}

/* Cambodia */
.flag-icon-kh {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/kh.svg);
}

/* Cameroon */
.flag-icon-cm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cm.svg);
}

/* Canada */
.flag-icon-ca {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ca.svg);
}

.flag-icon-cv {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cv.svg);
}
.flag-icon-ky {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ky.svg);
}
.flag-icon-cf {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cf.svg);
}
.flag-icon-td {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/td.svg);
}
.flag-icon-cl {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cl.svg);
}
.flag-icon-cn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cn.svg);
}
.flag-icon-hk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/hk.svg);
}
.flag-icon-mo {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mo.svg);
}
.flag-icon-cx {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cx.svg);
}
.flag-icon-cc {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cc.svg);
}
.flag-icon-co {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/co.svg);
}
.flag-icon-km {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/km.svg);
}
.flag-icon-cg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cg.svg);
}
.flag-icon-cd {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cd.svg);
}
.flag-icon-ck {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ck.svg);
}
.flag-icon-cr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cr.svg);
}
.flag-icon-ci {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ci.svg);
}
.flag-icon-hr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/hr.svg);
}
.flag-icon-cu {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cu.svg);
}
.flag-icon-cy {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cy.svg);
}
.flag-icon-cz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cz.svg);
}

/* Denmark */
.flag-icon-dk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/dk.svg);
}

/* Djibouti */
.flag-icon-dj {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/dj.svg);
}

/* Dominica */
.flag-icon-dm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/dm.svg);
}

/* Dominican Republic */
.flag-icon-do {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/do.svg);
}

/* Ecuador */
.flag-icon-ec {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ec.svg);
}

/* Egypt */
.flag-icon-eg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/eg.svg);
}

/* El Salvador */
.flag-icon-sv {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sv.svg);
}

/* Equatorial Guinea */
.flag-icon-gq {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gq.svg);
}

/* Eritrea */
.flag-icon-er {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/er.svg);
}

/* Estonia */
.flag-icon-ee {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ee.svg);
}

/* Eswatini */
.flag-icon-sz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sz.svg);
}

/* Ethiopia */
.flag-icon-et {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/et.svg);
}

/* Falkland Islands */
.flag-icon-fk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/fk.svg);
}

/* Faroe Islands */
.flag-icon-fo {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/fo.svg);
}

/* Fiji */
.flag-icon-fj {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/fj.svg);
}

/* Finland */
.flag-icon-fi {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/fi.svg);
}

/* France */
.flag-icon-fr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/fr.svg);
}

/* French Guiana */
.flag-icon-gf {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gf.svg);
}

/* French Polynesia */
.flag-icon-pf {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pf.svg);
}

/* Gabon */
.flag-icon-ga {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ga.svg);
}

/* Gambia */
.flag-icon-gm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gm.svg);
}

/* Georgia */
.flag-icon-ge {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ge.svg);
}

/* Germany */
.flag-icon-de {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/de.svg);
}

/* Ghana */
.flag-icon-gh {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gh.svg);
}

/* Gibraltar */
.flag-icon-gi {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gi.svg);
}

/* Greece */
.flag-icon-gr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gr.svg);
}

/* Greenland */
.flag-icon-gl {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gl.svg);
}

/* Grenada */
.flag-icon-gd {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gd.svg);
}

/* Guadeloupe */
.flag-icon-gp {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gp.svg);
}

/* Guam */
.flag-icon-gu {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gu.svg);
}

/* Guatemala */
.flag-icon-gt {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gt.svg);
}

/* Guernsey */
.flag-icon-gg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gg.svg);
}

/* Guinea */
.flag-icon-gn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gn.svg);
}

/* Guinea-Bissau */
.flag-icon-gw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gw.svg);
}

/* Guyana */
.flag-icon-gy {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gy.svg);
}

/* Haiti */
.flag-icon-ht {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ht.svg);
}

/* Honduras */
.flag-icon-hn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/hn.svg);
}

/* Hungary */
.flag-icon-hu {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/hu.svg);
}

/* Iceland */
.flag-icon-is {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/is.svg);
}

/* India */
.flag-icon-in {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/in.svg);
}

/* Indonesia */
.flag-icon-id {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/id.svg);
}

/* Iran */
.flag-icon-ir {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ir.svg);
}

/* Iraq */
.flag-icon-iq {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/iq.svg);
}

/* Ireland */
.flag-icon-ie {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ie.svg);
}

/* Isle of Man */
.flag-icon-im {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/im.svg);
}

/* Israel */
.flag-icon-il {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/il.svg);
}

/* Italy */
.flag-icon-it {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/it.svg);
}

/* Jamaica */
.flag-icon-jm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/jm.svg);
}

/* Japan */
.flag-icon-jp {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/jp.svg);
}

/* Jersey */
.flag-icon-je {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/je.svg);
}

/* Jordan */
.flag-icon-jo {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/jo.svg);
}

/* Kazakhstan */
.flag-icon-kz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/kz.svg);
}

/* Kenya */
.flag-icon-ke {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ke.svg);
}

/* Kiribati */
.flag-icon-ki {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ki.svg);
}

/* North Korea */
.flag-icon-kp {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/kp.svg);
}

/* South Korea */
.flag-icon-kr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/kr.svg);
}

/* Kuwait */
.flag-icon-kw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/kw.svg);
}

/* Kyrgyzstan */
.flag-icon-kg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/kg.svg);
}

/* Laos */
.flag-icon-la {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/la.svg);
}

/* Latvia */
.flag-icon-lv {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/lv.svg);
}

/* Lebanon */
.flag-icon-lb {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/lb.svg);
}

/* Lesotho */
.flag-icon-ls {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ls.svg);
}

/* Liberia */
.flag-icon-lr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/lr.svg);
}

/* Libya */
.flag-icon-ly {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ly.svg);
}

/* Liechtenstein */
.flag-icon-li {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/li.svg);
}

/* Lithuania */
.flag-icon-lt {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/lt.svg);
}

/* Luxembourg */
.flag-icon-lu {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/lu.svg);
}

/* Madagascar */
.flag-icon-mg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mg.svg);
}

/* Malawi */
.flag-icon-mw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mw.svg);
}

/* Malaysia */
.flag-icon-my {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/my.svg);
}

/* Maldives */
.flag-icon-mv {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mv.svg);
}

/* Mali */
.flag-icon-ml {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ml.svg);
}

/* Malta */
.flag-icon-mt {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mt.svg);
}

/* Marshall Islands */
.flag-icon-mh {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mh.svg);
}

/* Martinique */
.flag-icon-mq {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mq.svg);
}

/* Mauritania */
.flag-icon-mr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mr.svg);
}

/* Mauritius */
.flag-icon-mu {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mu.svg);
}

/* Mayotte */
.flag-icon-yt {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/yt.svg);
}

/* Mexico */
.flag-icon-mx {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mx.svg);
}

/* Micronesia (Federated States of) */
.flag-icon-fm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/fm.svg);
}

/* Moldova */
.flag-icon-md {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/md.svg);
}

/* Monaco */
.flag-icon-mc {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mc.svg);
}

/* Mongolia */
.flag-icon-mn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mn.svg);
}

/* Montenegro */
.flag-icon-me {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/me.svg);
}

/* Montserrat */
.flag-icon-ms {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ms.svg);
}

/* Morocco */
.flag-icon-ma {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ma.svg);
}

/* Mozambique */
.flag-icon-mz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mz.svg);
}

/* Myanmar */
.flag-icon-mm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mm.svg);
}

/* Namibia */
.flag-icon-na {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/na.svg);
}

/* Nauru */
.flag-icon-nr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/nr.svg);
}

/* Nepal */
.flag-icon-np {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/np.svg);
}

/* Netherlands */
.flag-icon-nl {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/nl.svg);
}

/* New Caledonia */
.flag-icon-nc {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/nc.svg);
}

/* New Zealand */
.flag-icon-nz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/nz.svg);
}

/* Nicaragua */
.flag-icon-ni {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ni.svg);
}

/* Niger */
.flag-icon-ne {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ne.svg);
}

/* Nigeria */
.flag-icon-ng {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ng.svg);
}

/* Niue */
.flag-icon-nu {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/nu.svg);
}

/* Norfolk Island */
.flag-icon-nf {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/nf.svg);
}

/* North Macedonia */
.flag-icon-mk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mk.svg);
}

/* Northern Mariana Islands */
.flag-icon-mp {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mp.svg);
}

/* Norway */
.flag-icon-no {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/no.svg);
}

/* Oman */
.flag-icon-om {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/om.svg);
}

/* Pakistan */
.flag-icon-pk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pk.svg);
}

/* Palau */
.flag-icon-pw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pw.svg);
}

/* Palestine */
.flag-icon-ps {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ps.svg);
}

/* Panama */
.flag-icon-pa {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pa.svg);
}

/* Papua New Guinea */
.flag-icon-pg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pg.svg);
}

/* Paraguay */
.flag-icon-py {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/py.svg);
}

/* Peru */
.flag-icon-pe {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pe.svg);
}

/* Philippines */
.flag-icon-ph {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ph.svg);
}

/* Pitcairn Islands */
.flag-icon-pn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pn.svg);
}

/* Poland */
.flag-icon-pl {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pl.svg);
}

/* Portugal */
.flag-icon-pt {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pt.svg);
}

/* Puerto Rico */
.flag-icon-pr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pr.svg);
}

/* Qatar */
.flag-icon-qa {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/qa.svg);
}

/* Réunion */
.flag-icon-re {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/re.svg);
}

/* Romania */
.flag-icon-ro {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ro.svg);
}

/* Russia */
.flag-icon-ru {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ru.svg);
}

/* Rwanda */
.flag-icon-rw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/rw.svg);
}

/* Saint Barthélemy */
.flag-icon-bl {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/bl.svg);
}

/* Saint Helena, Ascension, and Tristan da Cunha */
.flag-icon-sh {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sh.svg);
}

/* Saint Kitts and Nevis */
.flag-icon-kn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/kn.svg);
}

/* Saint Lucia */
.flag-icon-lc {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/lc.svg);
}

/* Saint Martin */
.flag-icon-mf {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/mf.svg);
}

/* Saint Pierre and Miquelon */
.flag-icon-pm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pm.svg);
}

/* Saint Vincent and the Grenadines */
.flag-icon-vc {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/vc.svg);
}

/* Samoa */
.flag-icon-ws {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ws.svg);
}

/* San Marino */
.flag-icon-sm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sm.svg);
}

/* Sao Tome and Principe */
.flag-icon-st {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/st.svg);
}

/* Saudi Arabia */
.flag-icon-sa {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sa.svg);
}

/* Senegal */
.flag-icon-sn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sn.svg);
}

/* Serbia */
.flag-icon-rs {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/rs.svg);
}

/* Seychelles */
.flag-icon-sc {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sc.svg);
}

/* Sierra Leone */
.flag-icon-sl {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sl.svg);
}

/* Singapore */
.flag-icon-sg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sg.svg);
}

/* Sint Maarten */
.flag-icon-sx {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sx.svg);
}

/* Slovakia */
.flag-icon-sk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sk.svg);
}

/* Slovenia */
.flag-icon-si {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/si.svg);
}

/* Solomon Islands */
.flag-icon-sb {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sb.svg);
}

/* Somalia */
.flag-icon-so {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/so.svg);
}

/* South Africa */
.flag-icon-za {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/za.svg);
}

/* South Georgia and the South Sandwich Islands */
.flag-icon-gs {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gs.svg);
}

/* South Sudan */
.flag-icon-ss {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ss.svg);
}

/* Spain */
.flag-icon-es {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/es.svg);
}

/* Sri Lanka */
.flag-icon-lk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/lk.svg);
}

/* Sudan */
.flag-icon-sd {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sd.svg);
}

/* Suriname */
.flag-icon-sr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sr.svg);
}

/* Svalbard and Jan Mayen */
.flag-icon-sj {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sj.svg);
}

/* Sweden */
.flag-icon-se {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/se.svg);
}

/* Switzerland */
.flag-icon-ch {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ch.svg);
}

/* Syria */
.flag-icon-sy {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/sy.svg);
}

/* Taiwan */
.flag-icon-tw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tw.svg);
}

/* Tajikistan */
.flag-icon-tj {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tj.svg);
}

/* Tanzania */
.flag-icon-tz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tz.svg);
}

/* Thailand */
.flag-icon-th {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/th.svg);
}

/* Timor-Leste */
.flag-icon-tl {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tl.svg);
}

/* Togo */
.flag-icon-tg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tg.svg);
}

/* Tokelau */
.flag-icon-tk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tk.svg);
}

/* Tonga */
.flag-icon-to {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/to.svg);
}

/* Trinidad and Tobago */
.flag-icon-tt {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tt.svg);
}

/* Tunisia */
.flag-icon-tn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tn.svg);
}

/* Turkey */
.flag-icon-tr {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tr.svg);
}

/* Turkmenistan */
.flag-icon-tm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tm.svg);
}

/* Turks and Caicos Islands */
.flag-icon-tc {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tc.svg);
}

/* Tuvalu */
.flag-icon-tv {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tv.svg);
}

/* Uganda */
.flag-icon-ug {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ug.svg);
}

/* Ukraine */
.flag-icon-ua {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ua.svg);
}

/* United Arab Emirates */
.flag-icon-ae {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ae.svg);
}

/* United Kingdom */
.flag-icon-gb {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gb.svg);
}

/* United States */
.flag-icon-us {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/us.svg);
}

/* Uruguay */
.flag-icon-uy {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/uy.svg);
}

/* Uzbekistan */
.flag-icon-uz {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/uz.svg);
}

/* Vanuatu */
.flag-icon-vu {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/vu.svg);
}

/* Vatican City */
.flag-icon-va {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/va.svg);
}

/* Venezuela */
.flag-icon-ve {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ve.svg);
}

/* Vietnam */
.flag-icon-vn {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/vn.svg);
}

/* Virgin Islands, British */
.flag-icon-vg {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/vg.svg);
}

/* Virgin Islands, U.S. */
.flag-icon-vi {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/vi.svg);
}

/* Wallis and Futuna */
.flag-icon-wf {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/wf.svg);
}

/* Western Sahara */
.flag-icon-eh {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/eh.svg);
}

/* Yemen */
.flag-icon-ye {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ye.svg);
}

/* Zambia */
.flag-icon-zm {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/zm.svg);
}

/* Zimbabwe */
.flag-icon-zw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/zw.svg);
}

/* Wales */
.flag-icon-gb-wls {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/gb-wls.svg);
}

/* United Nations */
.flag-icon-un {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/un.svg);
}

/* European Union */
.flag-icon-eu {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/eu.svg);
}
/* Abkhazia */
.flag-icon-ab {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ab.svg);
}

/* Nagorno-Karabakh Republic */
.flag-icon-nk {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/nk.svg);
}

/* Northern Cyprus */
.flag-icon-nc {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/nc.svg);
}

/* Sahrawi Arab Democratic Republic */
.flag-icon-eh {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/eh.svg);
}

/* Somaliland */
.flag-icon-null {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/null.svg);
}

/* South Ossetia */
.flag-icon-os {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/os.svg);
}

/* Taiwan (Republic of China) */
.flag-icon-tw {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/tw.svg);
}

/* Transnistria */
.flag-icon-t2 {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/t2.svg);
}