.discord-container {
    margin: 1rem auto;
    text-align: center;
    width: 50%;
    display: block;
    background-color: #ff90f0;
    border-radius: 5px;
    border-color: #fff;
    border-width: 2px;
    box-shadow: 0 0 8px rgba(0,0,0,.15);
}

.discord-container img {
    height: 70px;
    width: 210px;
    vertical-align: middle;
}

@media (min-width: 576px) {
    .discord-container {
        width: 556px;
    }
}

@media (min-width: 1px) {
    .discord-container {
        min-width: 220px;
    }
}