.scoreboard {
    justify-content: space-between;
    min-width: 556px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .15);
}

.live-team {
    flex: 1;
    padding-top: 4px;
    padding-bottom: 10px;
    border-radius: 4px;
    margin: 8px;
    background-color: #31313C;
}
.wrapper-live {
    margin-top: 100px!important;
}

.live-team ul {
    list-style: none;
    padding: 0;
}

.live-team h3 {
    text-align: center;
    margin-bottom: 10px;
}

.live-team li {
    margin-bottom: 5px;
}

/* Styles for Blue Team */
.blue-team {
    color: #3498db;
    border-bottom: 1px solid #515163;
    margin-top: 8px;
}

/* Styles for Red Team */
.red-team {
    color: #e74c3c;
    border-bottom: 1px solid #515163;
    margin-top: 8px;
}

.text-player {
    font-weight: 700 !important;
    color: #f9f9f9;
    font-size: large;
    font-family: 'Raleway-Bold', sans-serif;
}

.blue-team h3 {
    font-weight: 700 !important;
    color: #3498db;
    font-size: larger;
    font-family: 'Raleway-Bold', sans-serif;
}

.red-team h3 {
    font-weight: 700 !important;
    color: #e74c3c;
    font-size: larger;
    font-family: 'Raleway-Bold', sans-serif;
}

.blue-player {
    align-items: center;
    display: flex;
    padding-top: 8px;
}
.red-player {
    align-items: center;
    display: flex;
    padding-top: 8px;
}
.blue-player:not(:last-of-type) {
    padding-bottom: 8px;
    border-bottom: 1px solid #676775;
    margin-bottom: 0;
}

.red-player:not(:last-of-type) {
    padding-bottom: 8px;
    border-bottom: 1px solid #676775;
    margin-bottom: 0;
}

.blue-player .champion-image {
    text-align: right;
    border-left: 4px solid #3498db;
    color: var(--main500);
    margin-left: 12px;
    padding-left: 8px;
    margin-right: 4px;
    height: 48px;
}

.red-player .champion-image {
    text-align: right;
    border-left: 4px solid #e74c3c;
    color: var(--main500);
    margin-left: 12px;
    margin-right: 8px;
    padding-left: 8px;
    height: 48px;
}

.champion-image img {
    height: 100%;
    border-radius: 4px;
    height: 100%;
}

.image-border {
    height: 48px;
}

.red-scoreboard {
    margin-top: 16px;
}

.summoner-spell {
    display: flex;
    flex-direction: column;
    max-height: 48px;
    justify-content: space-between;
    margin-right: 0.25em;
    margin-right: 8px;
}

.summoner-spell img {
    max-height: 24px;
}

.tier-image {
    text-align: right;
    color: var(--main500);
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 8px;
    height: 48px;
    vertical-align: middle;
}

.summoner-name-scoreboard {
    min-width: 300px;
    margin-left: 4px;
    line-height: 1;
}

.tier-rank {
    text-align: center!important;    
    margin-left: auto;
    margin-right: 24px!important;
    vertical-align:middle;
}

.text-lighter-scoreboard {
    margin-right: auto;
    color: #9E9EB1!important;
    font-weight: 100;
    text-align: left;
    display: inline-block;
}

.winrate-inner {
    align-items: center;
    display: flex;
}

.winrate-visual {
    width: 100px;
    margin: 0px auto;
    display: block;
    margin-bottom: 4px;
}

.winrate-bar {
    position: relative;
    height: 8px;
    margin: 4px auto 0px;
    background-color: #424254;
}

.wins {
    position: absolute;
    height: 8px;
}

.winrate {
    font-size: 14px;
}

.text-small {
    font-size: 12px!important;
}

.pro-card {
    display: flex;
}
.scoreboard-name-pro {
    margin-right: 12px;
    align-items: center;
    display: flex;
    flex-basis: 20%;
    flex-grow: 1;
    vertical-align: middle;
}
.flag-scoreboard {
    margin-right: 12px!important;
    margin-left: 12px!important;
}
.top-team-element {
    min-width: 510px;
}
.top-team-element h3 {
    text-align: left;
    margin-left: 12px;
}

.scoreboard-element {
    align-items: center;
    display: flex;
}
.scoreboard-rank-align {
    margin-right: auto;
    text-align: center;
}
.scoreboard-tier-rank {
    width: 106px;
}


/*Loading*/

.text-light {
    font-size: 20px;
    font-family: 'Raleway-Bold', sans-serif;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px; /* This will make the container fill the entire viewport height */
    margin-top: 50px;
  }
  
  .spinner {
    animation: spin 2s linear infinite; /* This will apply the spinning animation */
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .loading-container p {
    margin-top: 16px; /* Add some space between the image and the text */
    font-size: 18px;
    font-weight: bold;
  }

  .loading-container img {
    height: 200px;
    margin-bottom: 40px;
  }