.chatbar-container {
    border-radius: 4px;
    height: 100%;
    display:block;
    background-color: #31313C;
    font-family: "Roboto",Sans-serif;
    color: #fff;
    margin-bottom: 0;
    margin-top: 8px;
    width: 378px;
    margin-left: 8px;
    height: 48%;
    box-shadow: 0 0 8px rgba(0,0,0,.15);
}
.seperator {
    border-top: 1px solid;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: #515163;
}
.admin-panel-text {
    margin-top: 0px;
}
.spacing {
    padding-bottom: 8px;
}