.Ladder-container-100 {
    border-radius: 4px;
    display: inline-block;
    background-color: #31313C;
    font-family: "Roboto", Sans-serif;
    color: #fff;
    margin-bottom: 0;
    width: 100%;
    vertical-align: top;
    box-shadow: 0 0 8px rgba(0, 0, 0, .15);
    margin-top: 8px;
}

.pink {
    color: #ff90f0!important;
}

h2 {
    margin: .25rem;
}
.top-text-container {
    display: flex !important;
}

.ladder-top {
    margin-left: auto !important;
    margin-right: 0 !important;
    background-color: #515163;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    padding-top: 3px;
}

.right-arrow {
    padding-left: 4px;
    padding-right: 1px;
}

div.container {
    padding: 0 0.5rem;
    background-color: #515163;
    border-radius: 4px;
}

.leaderboard {
    margin: 0!important;
}

.player:not(:last-of-type) {
    /*border-bottom: 1px solid #1C1C1F;*/
    border-bottom: 1px solid #31313C;
}

.player {
    display: flex;
    justify-content: space-between;
    padding: 0.915rem 0.5rem;
    /*background-color: grey;*/
}

.flag {
    height: 2rem;
    margin-right: 1rem;
    width: 2rem;
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}

img {
    height: 32px;
    max-width: 100%;
    vertical-align: middle;
}

.custom-wrapper {
    display: block;
    width: 1080px;
    margin-top: 74px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    height: 720px;
  }

.wrapper-ladder-100 {
    display: flex;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
}


@media (max-width: 575px) {
    .name {
        flex-basis: 50%;
        min-width: 50%;
    }

    .name .flag-icon {
        width: 1.5rem;
    }

    .name span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .role {
        margin: 0 0.5rem;
    }

    .position {
        width: 2rem;
    }

    .rank {
        margin: 0 0.5rem;
        width: 2rem;
    }

    .team {
        margin-left: 0.5rem;
        width: 2rem;
    }


}

@media (max-width: 767px) {
    .flag {
        height: 1.5rem;
        margin-right: 0.5rem;
        width: 1.5rem;
    }


}

@media (max-width: 992px) {
    .role {
        margin: 0 1rem;
    }

    .rank {
        margin: 0 1rem;
    }

    .team {
        margin-left: 1rem;
    }


}

@media only screen and (min-width: 0) and (max-width: 575px) {
    .hide-xs {
        display: none !important;
    }
}



.Ladder-container-100 .team {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 3rem;
    max-width: 4rem;
}

.rank-with-icon .rank-lp {
    font-size: .7rem;
    margin-top: -0.5rem;
    z-index: 1;
}

.rank-with-icon .rank-icon {
    height: 100%;
    opacity: .75;
    width: -moz-fit-content;
    width: fit-content;
}

.rank-with-icon {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    min-width: 2.5rem;
    padding: 0;
    position: relative;
}

.Ladder-container-100 .rank {
    align-items: center;
    display: flex;
    margin: 0 3rem;
}

.ladder-container-100 .twitch {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 3rem;
    max-width: 4rem;
}

.ladder-container-100 .youtube {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 3rem;
    max-width: 4rem;
}

.twitch {
    align-items: center;
    display: flex;

    margin-right: 6px;
    max-width: 4rem;
}

.youtube {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-left: 6px;
    margin-right: 3rem;
    max-width: 4rem;
}

.position {
    height: 2rem;
}

[class*=hint--] {
    display: inline-block;
    position: relative;
}

.Ladder-container-100 .role {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 3rem;
    width: 2rem;
}

.text-bold {
    font-weight: 700 !important;
    color: #f9f9f9;
    font-size: large;
}

.name {
    align-items: center;
    display: flex;
    flex-basis: 20%;
    flex-grow: 1;
    margin: auto;
}

a {
    color: #eee;
    color: var(--link-color);
    cursor: pointer;
    text-decoration: none;
    -webkit-text-decoration: var(--link-text-decoration);
    text-decoration: var(--link-text-decoration);
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
}

a:hover {
    color: #eee;
    color: var(--link-color);
    cursor: pointer;
    text-decoration: none;
    -webkit-text-decoration: var(--link-text-decoration);
    text-decoration: var(--link-text-decoration);
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.flag {
    height: 2rem;
    margin-right: 1rem;
    width: 2rem;
}

/*@media (min-width: 992px) {
    .Ladder-container-100 {
        max-width: 976px;
    }
    .custom-wrapper {
        width:auto;
    }
}*/


@media (min-width: 576px) {
    .Ladder-container-100 {
        max-width: 556px;
    }
    .custom-wrapper {
        width:auto;
    }
    .Ladder-container-100 .team {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 4rem;
    }
    .Ladder-container-100 .rank {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 4rem;
    }
    .Ladder-container-100 .role {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 4rem;
    }
    .Ladder-container-100 .youtube {
        margin-right: 1rem;
        max-width: 4rem;
    }
}
@media (min-width: 768px) {
    .Ladder-container-100 {
        min-width: 736px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }
    .wrapper-ladder-100 {
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
    }
    .custom-wrapper {
        width:auto;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: 116px;
    }
}

@media (max-width: 575px) {
    .Ladder-container-100 {
        max-width: 556px;
    }
    .custom-wrapper {
        width:auto;
    }
    .Ladder-container-100 .team {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 4rem;
    }
    .Ladder-container-100 .rank {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 4rem;
    }
    .Ladder-container-100 .role {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 4rem;
    }
    .Ladder-container-100 .youtube {
        margin-right: 1rem;
        max-width: 4rem;
        display: none;
    }
    .Ladder-container-100 .twitch {
        margin-right: 1rem;
        max-width: 4rem;
        display: none;
    }
}