.TopTenLadder-container {
    border-radius: 4px;
    height: auto;
    display:inline-block;
    background-color: #31313C;
    font-family: "Roboto",Sans-serif;
    color: #fff;
    margin-bottom: 0;
    margin-right: 8px;
    width: 700px;
    vertical-align: top;
    box-shadow: 0 0 8px rgba(0,0,0,.15);
}

h2 {
    margin: .25rem;
}


div.container {
    padding: 0 0.5rem;
    background-color: #515163;
    border-radius: 4px;
}

.leaderboard {
    margin: 8px;
}

.player:not(:last-of-type) {
    /*border-bottom: 1px solid #1C1C1F;*/
    border-bottom: 1px solid #31313C;
}

.player {
    display: flex;
    justify-content: space-between;
    padding: 0.915rem 0.5rem;
    /*background-color: grey;*/
}

.flag-icon-us {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/us.svg);
}

.flag-icon-kr {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/kr.svg);
}

.flag-icon-is {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/is.svg);
}

.flag-icon-pt {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/pt.svg);
}

.flag-icon-il {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/il.svg);
}
.flag-icon-cn {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/cn.svg);
}
.flag-icon-ca {
    background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/ca.svg);
}

.flag {
    height: 2rem;
    margin-right: 1rem;
    width: 2rem;
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}

img {
    height: 32px;
    max-width: 100%;
    vertical-align: middle;
}

@media (max-width: 575px) {
    .name {
        flex-basis: 50%;
        min-width: 50%;
    }

    .name .flag-icon {
        width: 1.5rem;
    }

    .name span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .role {
        margin: 0 0.5rem;
    }

    .position {
        width: 2rem;
    }

    .rank {
        margin: 0 0.5rem;
        width: 2rem;
    }

    .team {
        margin-left: 0.5rem;
        width: 2rem;
    }

    
}

@media (max-width: 767px) {
    .flag {
        height: 1.5rem;
        margin-right: 0.5rem;
        width: 1.5rem;
    }


}

@media (max-width: 992px) {
    .role {
        margin: 0 1rem;
    }

    .rank {
        margin: 0 1rem;
    }

    .team {
        margin-left: 1rem;
    }


}

@media only screen and (min-width: 0) and (max-width: 575px) {
    .hide-xs {
        display: none!important;
    }
}



.team {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    max-width: 4rem;
}

.rank-with-icon .rank-lp {
    font-size: .7rem;
    margin-top: -0.5rem;
    z-index: 1;
}

.rank-with-icon .rank-icon {
    height: 100%;
    opacity: .75;
    width: -moz-fit-content;
    width: fit-content;
}

.rank-with-icon {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    min-width: 2.5rem;
    padding: 0;
    position: relative;
}

.rank {
    align-items: center;
    display: flex;
    margin: 0 1rem;
}

.position {
    height: 2rem;
}

[class*=hint--] {
    display: inline-block;
    position: relative;
}

.role {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 1rem;
    width: 2rem;
}

.text-bold {
    font-weight: 700!important;
    color: #f9f9f9
    
}

.name {
    align-items: center;
    display: flex;
    flex-basis: 20%;
    flex-grow: 1;
}

a {
    color: #eee;
    color: var(--link-color);
    cursor: pointer;
    text-decoration: none;
    -webkit-text-decoration: var(--link-text-decoration);
    text-decoration: var(--link-text-decoration);
    transition: color .2s ease-in-out,background-color .2s ease-in-out;
}

a:hover {
    color: #eee;
    color: var(--link-color);
    cursor: pointer;
    text-decoration: none;
    -webkit-text-decoration: var(--link-text-decoration);
    text-decoration: var(--link-text-decoration);
    transition: color .2s ease-in-out,background-color .2s ease-in-out;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, main, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

*, :after, :before {
    box-sizing: border-box;
}

.flag {
    height: 2rem;
    margin-right: 1rem;
    width: 2rem;
}

